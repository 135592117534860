<template>
  <div class="swiper">
    <div class="swiper-container">
      <div class="swiper-wrapper">
        <div
          v-for="(item, index) in imgList"
          :key="index"
          class="swiper-slide"
          data-swiper-autoplay="5000"
        >
          <img :src="item.img" @click="nav(item)">
        </div>
      </div>
      <!-- 如果需要导航按钮 -->
      <div class="swiper-button-prev" />
      <div class="swiper-button-next" />
    </div>
  </div>
</template>
<script>
import Swiper from 'swiper'
import 'swiper/swiper.min.css'
export default {
  props: ['imgList'],

  data() {
    return {}
  },
  mounted() {
    new Swiper('.swiper-container', {
      pagination: '.swiper-pagination',
      loop: true,
      delay: 5000, // 1秒切换一次
      autoplay: true,
      autoplay: {
        delay: 5000 // 1秒切换一次
      },
      prevButton: '.swiper-button-prev',
      nextButton: '.swiper-button-next'
    })
  },
  methods: {
    nav(item) {
      window.open(item.skipImg, '_blank')
    }
  }
}
</script>
<style lang="less" scoped>
.swiper {
  width: 100%;
  height: 100%;
  .swiper-container {
    width: 100%;
    height: 100%;
    .swiper-wrapper {
      width: 100%;
      height: 100%;
      .swiper-slide {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
